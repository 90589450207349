import Content from './components/content/Content';
import './App.css';

function App() {
  return (
    <Content />
  );
}

export default App;
