import React, { useState, useCallback, useEffect } from 'react';
import { FaGithub, FaListAlt, FaImage } from 'react-icons/fa';
import './projects.css';
import Modal from '../modal/Modal';
import { useInView } from 'react-intersection-observer';

const ProjectSection = (
  { projectTitle,
    projectLink,
    projectParticipationType,
    projectChips,
    projectDescr,
    projectInvolvement,
    projectImage,
    className,
    imageOnLeft,
    id }) => {
  
  const [hovering, setHovering] = useState(false);

  const listTabNum = 0;
  const imageTabNum = 1;

  const [ showSectionNumber, setShowSectionNumber ] = useState(listTabNum);

  const openListTab = useCallback(() => {
      setShowSectionNumber(listTabNum);
  }, [setShowSectionNumber, listTabNum]);

  const openImageTab = useCallback(() => {
      setShowSectionNumber(imageTabNum);
  }, [setShowSectionNumber, imageTabNum]);

  const popupImg = <img src={projectImage} alt={'project screenshot'} className={'projectPopupImage'} />;

  const cardContent = <>
    <div className={'projectLink'}>
    <a target={'_blank'} rel={'noreferrer'} href={projectLink}>
      <div className={'projectPopupTitle'}>
        {projectTitle}
      </div>
      <div className={'projectPopupLinkContainer'}><FaGithub /></div>
    </a>
    </div>
    <div className={'projectPopupDescription'}>
      {projectDescr}
    </div>
    <div className={'projectSectionSelector'}>
      <div
      onClick={openListTab}
      className={'selectorButton listButton ' + (showSectionNumber === 0 ? 'show' : 'hide')}>
          <FaListAlt />
      </div>
      <div
      onClick={openImageTab}
      className={'selectorButton imageButton ' + (showSectionNumber === 1 ? 'show' : 'hide')}>
          <FaImage />
      </div>
    </div>
    <div className={'projectPopupDetails'}>
      <div className={'projectPopupImageContainer ' + (showSectionNumber === 1 ? 'show' : 'hide')}>
          {popupImg}
      </div>
      <div className={'projectPopupListContainer ' + (showSectionNumber === 0 ? 'show' : 'hide')}>
          <div className={'projectPopupListHeading'}>
            My tasks:
          </div>
          {projectInvolvement}
      </div>
    </div>
    <div className={'projectPopupSkills'}>
      {projectChips}
    </div>
  </>;

  const [showProjectDetail, setShowProjectDetail] = useState(false);

  const onCardClick = useCallback(() => {
    setShowProjectDetail(true);
  }, [setShowProjectDetail]);

  const { ref, inView } = useInView({threshold: 0.3});
  
  const [hasBeenInView, setHasBeenInView] = useState(false);
  useEffect(() => {
    if (inView) {
      setHasBeenInView(inView);
    }
  }, [inView, setHasBeenInView])

  return (
    <>
    <Modal show={showProjectDetail} hideModal={() => {setShowProjectDetail(false)}}>{cardContent}</Modal>
    <div ref={ref} id={id} className={'projectCardResponsive ' + (hasBeenInView ? 'showCard' : 'hideCard')}>
      <div className={'projectCard'} onClick={() => onCardClick()} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
        <div className={'projectContentContainer'}>
          <div className={'projectImageContainer'}>
            <img alt={'project screenshot'} src={projectImage} className={'projectImage' + (hovering ? ' hovering' : '')} />
          </div>
          <div className={'projectCardDescriptionWrapper'}>
            <div className={'projectCardDescriptionContainer'}>
              <div className={'projectTitle'}>
                {projectTitle}
              </div>
              {projectParticipationType}
              <div className={'projectChips'}>
                {projectChips}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default ProjectSection